import React from 'react'

const Terms = () => {
  return (
    <div style={{marginTop: '88px'}}>
   <div>
  <div className="bg-img-hero" style={{backgroundImage: 'url(https://res.cloudinary.com/dzxyvdq14/image/upload/v1619921140/abstract-shapes-12.svg)'}}>
    <div className="container space-top-3 space-top-lg-4 space-bottom-2 position-relative z-index-2">
      <div className="w-md-80 w-lg-60 text-center mx-md-auto">
        <h1>FX GLOBAL Terms &amp; Conditions</h1>
        <p>Effective date: 13 July 2021</p>
      </div>
    </div>
  </div>
  {/* End Hero Section */}
  {/* Terms Content Section */}
  <div className="container space-2 space-bottom-lg-3">
    <div className="row">
      <div id="stickyBlockStartPoint" className="col-md-4 col-lg-3 mb-7 mb-md-0">
        {/* Nav */}
        <nav className="js-sticky-block card card-bordered" data-hs-sticky-block-options="{
           &quot;parentSelector&quot;: &quot;#stickyBlockStartPoint&quot;,
           &quot;targetSelector&quot;: &quot;#logoAndNav&quot;,
           &quot;breakpoint&quot;: &quot;md&quot;,
           &quot;startPoint&quot;: &quot;#stickyBlockStartPoint&quot;,
           &quot;endPoint&quot;: &quot;#stickyBlockEndPoint&quot;,
           &quot;stickyOffsetTop&quot;: 24,
           &quot;stickyOffsetBottom&quot;: 24
         }">
          <div className="card-body">
            <ul className="js-scroll-nav nav nav-sm nav-x-0 flex-column">
              <li className="nav-item">
                <a className="nav-link font-weight-bold mb-2" href="#services">1. Using our services</a>
                <ol className="navbar-nav">
                  <li className="nav-item">
                    <a className="nav-link mb-2" href="#personal-data">A. Personal Data that we collect about you</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link mb-2" href="#information">B. Information that we collect automatically on our Sites</a>
                  </li>
                </ol>
              </li>
              <li className="nav-item">
                <a className="nav-link font-weight-bold mb-2" href="#privacy">2. Privacy and copyright protection</a>
              </li>
              <li className="nav-item">
                <a className="nav-link font-weight-bold mb-2" href="#yourContent">3. Your content in our services</a>
              </li>
              <li className="nav-item">
                <a className="nav-link font-weight-bold mb-2" href="#LIMITATION">4. LIMITATION OF LIABILITY</a>
              </li>
              <li className="nav-item">
                <a className="nav-link font-weight-bold mb-2" href="#INDEMNITY">5. INDEMNITY AGREEMENT</a>
              </li>
              <li className="nav-item">
                <a className="nav-link font-weight-bold mb-2" href="#SECURITY">6. WEBSITE SECURITY AND RESTRICTION</a>
              </li>
              <li className="nav-item">
                <a className="nav-link font-weight-bold mb-2" href="#CONFIDENTIALITY">7. CONFIDENTIALITY AND PASSWORD SECURITY</a>
              </li>
            </ul>
          </div>
        </nav>
        {/* End Nav */}
      </div>
      <div className="col-md-8 col-lg-9">
        <div id="intro" className="space-bottom-1">
          {/* Title */}
          <div className="mb-3">
            <h2 className="h3">Welcome to FX GLOBAL</h2>
          </div>
          {/* End Title */}
          <p>Thanks for using our products and services ("Services"). The Services are provided by FX GLOBAL.com. ("FX GLOBAL"), a web based investment company with offices in, United Kingdom, United States of America and Canada.
          </p><p>By using our Services, you are agreeing to these terms. Please read them carefully.</p>
          <p>Our Services are very diverse, so sometimes additional terms or product requirements (including age requirements) may apply. Additional terms will be available with the relevant Services, and those additional terms become part of your agreement with us if you use those Services.</p>
        </div>
        <div id="services" className="space-bottom-1">
          {/* Title */}
          <div className="mb-3">
            <h3>1. Using our services</h3>
          </div>
          {/* End Title */}
          <p>You must follow any policies made available to you within the Services.</p>
          <p>Don't misuse our Services. For example, don't interfere with our Services or try to access them using a method other than the interface and the instructions that we provide. You may use our Services only as permitted by law, including applicable export and re-export control laws and regulations. We may suspend or stop providing our Services to you if you do not comply with our terms or policies or if we are investigating suspected misconduct.</p>
          <p>Using our Services does not give you ownership of any intellectual property rights in our Services or the content you access. You may not use content from our Services unless you obtain permission from its owner or are otherwise permitted by law. These terms do not grant you the right to use any branding or logos used in our Services. Don't remove, obscure, or alter any legal notices displayed in or along with our Services.</p>
          {/* Title */}
          <div id="personal-data" className="mb-3">
            <h4>A. Personal Data that we collect about you.</h4>
          </div>
          {/* End Title */}
          <p>Personal Data is any information that relates to an identified or identifiable individual. The Personal Data that you provide directly to us through our Sites will be apparent from the context in which you provide the data. In particular:</p>
          <ul>
            <li className="pb-2">When you register for a FX GLOBAL account we collect your full name, email address, and account log-in credentials.</li>
            <li className="pb-2">When you fill-in our online form to contact our sales team, we collect your full name, work email, country, and anything else you tell us about your project, needs and timeline.</li>
            <li className="pb-2">When you use the "Remember Me" feature of FX GLOBAL Checkout, we collect your email address, payment card number, CVC code and expiration date.</li>
          </ul>
          <p>When you respond to FX GLOBAL emails or surveys we collect your email address, name and any other information you choose to include in the body of your email or responses. If you contact us by phone, we will collect the phone number you use to call FX GLOBAL. If you contact us by phone as a FX GLOBAL User, we may collect additional information in order to verify your identity.</p>
          {/* Title */}
          <div id="information" className="mb-3">
            <h4>B. Information that we collect automatically on our Sites.</h4>
          </div>
          {/* End Title */}
          <p>We also may collect information about your online activities on websites and connected devices over time and across third-party websites, devices, apps and other online features and services. We use Google Analytics on our Sites to help us analyze Your use of our Sites and diagnose technical issues.</p>
          <p>To learn more about the cookies that may be served through our Sites and how You can control our use of cookies and third-party analytics, please see our Cookie Policy.</p>
        </div>
        <div id="privacy" className="space-bottom-1">
          {/* Title */}
          <div className="mb-3">
            <h3>2. Privacy and copyright protection</h3>
          </div>
          {/* End Title */}
          <p>FX GLOBAL's privacy policies explain how we treat your personal data and protect your privacy when you use our Services. By using our Services, you agree that FX GLOBAL can use such data in accordance with our privacy policies.</p>
          <p>We respond to notices of alleged copyright infringement and terminate accounts of repeat infringers according to the process set out in the U.S. Digital Millennium Copyright Act.</p>
          <p>We provide information to help copyright holders manage their intellectual property online. If you think somebody is violating your copyrights and want to notify us, you can find information about submitting notices and FX GLOBAL's policy about responding to notices in <a href="mailto:support@FX GLOBAL.com">our Help Center</a>.</p>
        </div>
        <div id="yourContent">
          {/* Title */}
          <div className="mb-3">
            <h3>3. Your content in our services</h3>
          </div>
          {/* End Title */}
          <p>Some of our Services allow you to upload, submit, store, send or receive content. You retain ownership of any intellectual property rights that you hold in that content. In short, what belongs to you stays yours.</p>
          <p>When you upload, submit, store, send or receive content to or through our Services, you give FX GLOBAL (and those we work with) a worldwide license to use, host, store, reproduce, modify, create derivative works (such as those resulting from translations, adaptations or other changes we make so that your content works better with our Services), communicate, publish, publicly perform, publicly display and distribute such content. The rights you grant in this license are for the limited purpose of operating, promoting, and improving our Services, and to develop new ones. This license continues even if you stop using our Services (for example, for a business listing you have added to FX GLOBAL Maps). Some Services may offer you ways to access and remove content that has been provided to that Service. Also, in some of our Services, there are terms or settings that narrow the scope of our use of the content submitted in those Services. Make sure you have the necessary rights to grant us this license for any content that you submit to our Services.</p>
          <p>
          </p></div>
        <div id="LIMITATION">
          <div className="mb-3">
            <h3>4. LIMITATION OF LIABILITY</h3>
          </div>
          In no event shall FX GLOBAL be responsible for any damages, losses, or liabilities including without limitation, direct or indirect, special incidental, consequential damages, losses, or liabilities, in connection with your use of this web site or your reliance on or use or inability to use the information, materials, products, and services on this web site, or in connection with any failure of performance, error, omission, interruption, defect, delay in operation or transmission, computer virus, or line or system failure.<br />
        </div>
        <div id="INDEMNITY">
          <div className="mb-3">
            <h3>5. INDEMNITY AGREEMENT</h3>
          </div>
          As a condition of your use of the Services, you agree to indemnify and hold FX GLOBAL, its affiliates, and their respective partners, directors, employees, and agents harmless from and against any and all claims, losses, liability, costs, and expenses (including but not limited to attorneys’ fees) arising from your use of the web site or from your violation of these Terms..<br />
        </div>
        <div id="SECURITY">
          <div className="mb-3">
            <h3>6. WEBSITE SECURITY AND RESTRICTION</h3>
          </div>
          As a condition to your use of Services, you agree that you will not, and you will not take any action intended to: (i) access data that is not intended for you; (ii) invade the privacy of, obtain the identity of, or obtain any personal information about any other user of this web site; (iii) probe, scan, or test the vulnerability of this web site or FX GLOBAL network or breach security or authentication measures without proper authorization; (iv) attempt to interfere with service to any user, host, or network or otherwise attempt to disrupt our business; or (v) send unsolicited mail, including promotions and/or advertising of products and services. Unauthorized use of the web site or Services, including but not limited to unauthorized entry into FX GLOBAL systems, misuse of passwords, or misuse of any information posted to a website, is strictly prohibited. Portions of the website are designated for password access only as indicated by a lock icon. In these instances, if you do not have an authorized password, no access is permitted.<br />
        </div>
        <div id="CONFIDENTIALITY">
          <div className="mb-3">
            <h3>7. CONFIDENTIALITY AND PASSWORD SECURITY</h3>
          </div>
          Certain parts of this website may be protected by passwords or require a login. You are responsible for maintaining the confidentiality of any usernames, passwords, security questions, and answers. All information available through the privileged area of the site is confidential and proprietary to us. This includes all investment information and results, offering materials, financial statements, and other information provided through this part of the site. You will use your best efforts to keep all this information strictly confidential. You will not disclose any of this information to any person or use it for any purpose other than those strictly permitted by us.
          <p />
        </div>
        {/* Sticky Block End Point */}
        <div id="stickyBlockEndPoint" />
      </div>
    </div>
  </div>
  {/* End Terms Content Section */}
</div>
</div>
  )
}

export default Terms
